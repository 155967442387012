import React from "react";
import { MdAppRegistration, MdIosShare, MdMobileScreenShare, MdContentPaste } from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";

const ServicesSocialMedia = () => {
  return (
    <div className="py-10 px-4 animate-fadeInUp">
      <div className="max-w-6xl mx-auto">
        {/* Hizmet Başlığı */}
        <div className="text-center mb-10 mt-20">
          <h1 className="text-3xl md:text-5xl font-bold text-gray-800 mb-4">
            Sosyal Medya İçerik Üretimi
          </h1>
          <p className="text-gray-600 text-lg">
          Markanızın hikayesini etkileyici ve yaratıcı içeriklerle sosyal medyada öne çıkarıyoruz.
          </p>
        </div>

        {/* İçerik ve Görsel Kısmı */}
        <div className="flex flex-col 1000:flex-row items-center bg-white rounded-lg shadow-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 sm:pt-0 pt-10">
          {/* Görsel */}
          <div className="w-full 1000:w-1/2 h-64 1000:h-auto">
            <img
              src="../socialMedia.jpg"
              alt="Service"
              className="w-full h-full object-cover"
            />
          </div>
          
          {/* Detaylar */}
          <div className="w-full 1000:w-1/2 p-6 1000:p-10">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Sosyal Medya İçerik Üretimi
            </h2>
            <p className="text-gray-700 mb-4">
            Günümüzün dijital dünyasında güçlü bir sosyal medya varlığı, markaların hedef kitlelerine ulaşabilmesi için en önemli araçlardan biri haline gelmiştir. Bu nedenle, işletmenizin ihtiyaçlarına özel olarak hazırlanmış, yaratıcı ve etkileyici sosyal medya içerikleri üretiyoruz.
            </p>
            <p className="text-gray-700 mb-4">
            Hedef kitlenize en etkili şekilde ulaşmak için marka kimliğinizi yansıtan görseller, videolar ve metinlerle sosyal medya platformlarında fark yaratıyoruz. İçeriklerimiz, her platformun dinamiklerine uygun olarak hazırlanır ve markanızı en iyi şekilde temsil eder. Düzenli analizler yaparak stratejinizi güncel tutar, etkileşim oranlarını artırır, takipçi kitlenizi büyütmeye odaklanırız.
            </p>
            <div className="mt-6">
              <a href="/contact" className="bg-sidoma-blue text-white px-6 py-2 rounded-md shadow hover:bg-sidoma-white-hover transition duration-200">
                Bilgi Alın
              </a>
            </div>
          </div>
        </div>

        {/* Key Features */}
        <div className="mt-32">
          <h3 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Hizmet İçeriği</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                icon: <MdAppRegistration className="text-sidoma-blue text-4xl" />,
                title: 'İçerik Stratejisi Geliştirme',
                description: 'Sosyal medya içerik yönetimi hizmetimiz, hedef kitle analizi yaparak markanızın içeriğini en iyi şekilde yönlendirmekle başlar. Bu süreçte, rekabet analizi ile sektördeki konumunuzu belirleyerek, içerik stratejilerinizi optimize ediyoruz.',
              },
              {
                icon: <MdIosShare className="text-sidoma-blue text-4xl" />,
                title: 'Paylaşım ve Dağıtım',
                description: 'Düzenli ve planlı bir paylaşım süreci sağlamak için içerik takvimi oluşturuyoruz. Böylece takipçilerinizle sürekli etkileşimde kalarak marka bilinirliğinizi artırıyoruz.',
              },
              {
                icon: <MdMobileScreenShare className="text-sidoma-blue text-4xl" />,
                title: 'Platforma Özel İçerik Stratejileri',
                description: 'Platforma özel içerik stratejileri geliştiriyor ve her sosyal medya kanalının dinamiklerine uygun içerikler üretiyoruz. Bu sayede, daha fazla görünürlük ve etkileşim sağlıyoruz.',
              },
              {
                icon: <MdContentPaste className="text-sidoma-blue text-4xl" />,
                title: 'İçerik Üretimi',
                description: 'İçerik üretim sürecinde metin, görsel ve video içerikler tasarlayarak marka kimliğinize uygun, etkileyici içerikler oluşturuyoruz. Aynı zamanda SEO uyumlu içerikler hazırlayarak, arama motorlarında görünürlüğünüzü artırıyoruz.',
              },
              {
                icon: <HiOutlineDocumentReport className="text-sidoma-blue text-4xl" />,
                title: 'Aylık Raporlar',
                description: 'Aylık raporlar ile içerik performansını izliyor ve düzenli değerlendirmeler yaparak stratejilerinizi güncelliyoruz. Başarı metriklerini analiz ederek, içerik stratejinizi sürekli olarak geliştiriyoruz.',
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg transform hover:-translate-y-2 transition duration-300 ease-in-out"
              >
                <div className="flex items-center justify-center mb-4">
                  {feature.icon}
                </div>
                <h4 className="text-xl font-semibold text-gray-800 mb-2 text-center">{feature.title}</h4>
                <p className="text-gray-600 text-center">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Diğer Hizmetler */}
        <div className="mt-32 mb-16">
          <h3 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Diğer Hizmetlerimiz</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[
              { title: "Yazılım Geliştirme", description: "İşinizi en iyi şekilde yansıtan, hızlı, güvenli ve kullanıcı dostu web siteleri tasarlıyoruz.", link: "/servicesDevelopment" },
              { title: "Yönetim Sistemleri Danışmanlığı", description: "Firmanızı kurumsal bir seviyeye taşırken, kurumsallığın sürekliliğini sağlıyoruz.", link: "/servicesConsulting" },
              { title: "Ürünlerimiz", description: "İşinizi zirveye taşıyacak, güçlü dijital çözümlerimiz.", link: "/products" },
            ].map((service, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-md transition-shadow duration-200">
                <h4 className="text-xl font-semibold text-gray-800 mb-2">{service.title}</h4>
                <p className="text-gray-600 mb-4">{service.description}</p>
                <a href={service.link} className="text-sidoma-blue hover:text-indigo-800 hover:underline transition-colors duration-200">
                  Daha Fazlası →
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSocialMedia;