import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Services from './pages/services';
import Products from './pages/products';
import Contact from './pages/contact';
import References from './pages/references';
import Blog from './pages/blog';
import Layout from './components/layout';
import './App.css';
import BlogDetail from './pages/blogDetail';
import ServicesDevelopment from './pages/servicesDevelopment';
import ServicesSocialMedia from './pages/servicesSocialMedia';
import ServicesConsulting from './pages/servicesConsulting';
import ProductsQdms from './pages/productsQdms';
import ProductsEba from './pages/productsEba';
import ProductsEnsemble from './pages/productsEnsemble';



function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="services" element={<Services />} />
          <Route path="servicesDevelopment" element={<ServicesDevelopment />} />
          <Route path="servicesSocialMedia" element={<ServicesSocialMedia />} />
          <Route path="servicesConsulting" element={<ServicesConsulting />} />
          <Route path="products" element={<Products />} />
          <Route path="productsQdms" element={<ProductsQdms />} />
          <Route path="productsEba" element={<ProductsEba />} />
          <Route path="productsEnsemble" element={<ProductsEnsemble />} />
          <Route path="contact" element={<Contact />} />
          <Route path="references" element={<References />} />
          <Route path="blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
        </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
