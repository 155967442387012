import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaStar } from "react-icons/fa";

const reviews = [
  {
    rating: 5,
    company: "Zorlu Enerji",
    name: "Evrim Arabacı",
    comment: "Taleplerimize hızlı ve çözümcü bir yaklaşımla dönüş yapılıyor.",
    image: "../zorlu.png",
  },
  {
    rating: 5,
    company: "EÜAŞ",
    name: "Tayyibe Tuncay",
    comment: "Aldığımız eğitim online olmasına rağmen keyifli ve verimli bir eğitim süreci geçirdik. Emekleri için teşekkür ediyoruz.",
    image: "../eüaş.png",
  },
  {
    rating: 5,
    company: "Biruni Üniversite Hastanesi",
    name: "Elif Esendemir",
    comment: "Sidoma tarafından gerekli eğitimler açık ve anlaşılır verilmiştir. Kurulum sonrası her aşamada kullanımda gerekli destekleri alabildik.",
    image: "../biruni.png",
  },
];

const CustomerCommentCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dotsClass: "slick-dots custom-dots",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="max-w-4xl md:max-w-2xl lg:max-w-4xl lg:p-20 md:mx-auto mx-5 my-8 md:mb-40 mb-28 p-4 py-7 bg-white bg-opacity-70 rounded-xl shadow-custom overflow-hidden">
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <div key={index} className="p-4 max-w-full overflow-hidden">
            <div className="flex items-start text-left">
              {/* Profil Resmi */}
              <img
                src={review.image}
                alt={review.name}
                className="w-12 h-12 lg:size-28 lg:mr-10 rounded-full mr-4"
              />
              <div>
                {/* Yıldızlar */}
                <div className="flex mb-2">
                  {[...Array(5)].map((_, i) => (
                    <FaStar
                      key={i}
                      className={`${
                        i < review.rating ? "text-yellow-400" : "text-gray-300"
                      }`}
                    />
                  ))}
                </div>
                {/* Şirket, Kişi Adı ve Yorum */}
                <h3 className="text-lg lg:text-2xl font-semibold text-gray-700">{review.company}</h3>
                <h4 className="text-md lg:text-lg font-medium text-gray-600">{review.name}</h4>
                <p className="text-sm lg:text-lg text-gray-500 mt-2">{review.comment}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default CustomerCommentCarousel;
