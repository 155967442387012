import React from 'react';



function FlipCard({ imageSrc, description }) {
  return (


    <div className="flex flex-wrap justify-center space-x-4 p-4">
      
        <div
        class="w-[300px] h-[300px] bg-transparent cursor-pointer group perspective"
      >
        <div
          class="relative preserve-3d group-hover:my-rotate-y-180 w-full h-full duration-1000 bg-sidoma-blue shadow-custom rounded-2xl"
        >
          <div class="absolute backface-hidden w-full h-full flex justify-center items-center ">
            <img src={imageSrc} class=" size-auto  rounded-2xl " alt=''/> 
          </div>

          {/* a  transition özellikleri tailwind.configte eklendi*/}

          {/* rka taraf */}
          <div
            class="absolute my-rotate-y-180 backface-hidden w-full h-full  overflow-hidden"
          >
            <div
              class="text-center flex flex-col gap-7 items-center justify-center h-full w-full text-sidoma-white px-2 bg-sidoma-blue  rounded-2xl"
            >
              <p>
                {description}
              </p>
              <a href='/contact'
                class="bg-sidoma-white hover:bg-blue-100 px-8 py-2  text-sidoma-blue font-bold rounded-full transition duration-200 ease-in-out"
              >
                BİLGİ AL
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlipCard;