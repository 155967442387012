import React from 'react';
import { TbFileAnalytics } from 'react-icons/tb';
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { PiTreeStructureFill } from "react-icons/pi";
import { LuBrainCircuit } from "react-icons/lu";
import { FaSyncAlt } from 'react-icons/fa';
import { IoIosNotifications } from "react-icons/io";
import Features from './Features.jsx';

const EnsembleFeatures = () => {
  const features = [
    {
      icon: <IoIosNotifications  className="text-ensemble-turkuaz text-5xl" />,
      title: "İş Takibi ve Hatırlatmaların Sorumlulara Bildirimi",
      description: "Görevleri takip edin, zamanında bildirim gönderin.",
    },
    {
      icon: <PiTreeStructureFill className="text-ensemble-turkuaz text-5xl" />,
      title: "Kurumsal Mimarinin Oluşturulması",
      description: "Süreçleri ve yapıları tek çatı altında planlayın.",
    },
    {
      icon: <FaMoneyBillTrendUp  className="text-ensemble-turkuaz text-5xl" />,
      title: "Maliyet, Zaman ve İş Gücü Kayıplarının Önüne Geçme",
      description: "Verimlilikle maliyetleri ve kayıpları azaltın.",
    },
    {
      icon: <TbFileAnalytics className="text-ensemble-turkuaz text-5xl" />,
      title: "Kurum Swot Analizi",
      description: "Güçlü, zayıf yönleri, fırsatları ve tehditleri keşfedin.",
    },
    {
      icon: <LuBrainCircuit className="text-ensemble-turkuaz text-4xl" />,
      title: "Kurumsal Hafızanın Oluşturulması",
      description: "Bilgi ve belgeleri merkezi bir platformda saklayın.",
    },
    {
      icon: <FaSyncAlt className="text-ensemble-turkuaz text-4xl" />,
      title: "ERP Sistemleri ve Kurumsal Uygulamalar ile Entegrasyon",
      description: "Mevcut sistemlerle uyumlu, kolay entegrasyon sağlayın.",
    },
  ];

  return (
    <div>
      <Features features={features} />
    </div>
  );
};

export default EnsembleFeatures;