import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";

const Footer = () => {
  return (
    <footer className="bg-sidoma-blue text-sidoma-white py-8 font-archivo">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Company Info */}
          <div>
            <h3 className=" text-4xl font-bold text-white mb-4 font-clashDisplay">
              SİDOMA
            </h3>
            <p className="font-archivo">
              Mükemmellik yolculuğundaki rehberiniz.
            </p>
          </div>

          {/* Navigation Links */}
          <div>
            <h3 className="text-xl font-bold text-white mb-4">Sayfalarımız</h3>
            <ul className="space-y-2">
              <li>
                <a href="/services" className="hover:underline">
                  Hizmetlerimiz
                </a>
              </li>
              <li>
                <a href="/products" className="hover:underline">
                  Ürünlerimiz
                </a>
              </li>
              <li>
                <a href="/references" className="hover:underline">
                  Referanslarımız
                </a>
              </li>
              <li>
                <a href="/blog" className="hover:underline">
                  Blog
                </a>
              </li>
            </ul>
          </div>

          {/* Social Media */}
          <div>
            <h3 className="text-xl font-bold text-white mb-4">
              Bizi takip edin.
            </h3>
            <div className="flex space-x-4 mb-10">
              <a
                href="https://www.linkedin.com/company/sidomaco/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sidoma-white text-2xl transition-colors duration-300 hover:text-gray-300"
              >
                <FaLinkedin />
              </a>

              {/* <a
                href=""
                target="_blank"
                rel="noopener noreferrer"
                className="text-sidoma-white text-2xl transition-colors duration-300 hover:text-gray-300"
              >
                <FaYoutube />
              </a> */}
              <a
                href="https://www.instagram.com/sidoma.co/?igsh=MWFobGxtOHhocWN4MQ%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sidoma-white text-2xl transition-colors duration-300 hover:text-gray-300"
              >
                <RiInstagramFill />
              </a>
            </div>
            <a
              href="/contact"
              className="hover:bg-red-400 bg-red-600 px-5 font-semibold py-2 rounded transition duration-200 ease-in-out"
            >
              İletişim
            </a>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-8 border-t border-sidoma-white pt-4 text-center">
          <p>
            &copy; 2024 Copyright 2014-2024 Tüm Hakkı Saklıdır&nbsp;{" "}
            <span className="font-clashDisplay">SİDOMA</span>{" "}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
