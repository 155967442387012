
const UserCommentsContent = [
  {
    name: "Evrim Arabacı",
    title: "Zorlu Enerji",
    feedback: "Taleplerimize hızlı ve çözümcü bir yaklaşımla dönüş yapılıyor.",
    image: "zorlu.png", 
  },
  {
    name: "Tayyibe Tuncay",
    title: "EÜAŞ",
    feedback: "Aldığımız eğitim online olmasına rağmen keyifli ve verimli bir eğitim süreci geçirdik. Emekleri için teşekkür ediyoruz.",
    image: "eüaş.png",
  },
  {
    name: "Yunus Emre Öztürk",
    title: "SANKO Holding A.Ş",
    feedback: "SİDOMA Danışmanlık ile uzun süredir devam eden bir iş ortaklığımız mevcut. Hakan Bey ve ekibi gerçekten yüksek bilgisi hızlı geri dönüşleri ve kalıcı çözümleri ile şirket süreçlerimize çok katkı sağlıyor. Verilen hizmetten çok memnunuz ve teşekkür ederiz. Ortaklığımızın uzunca yıllar devam etmesini diliyoruz.",
    image: "sanko.png",
  },
  {
    name: "Elif Esendemir",
    title: "Biruni Üniversite Hastanesi",
    feedback: "QDMS kullanımı ve kurulumu kolay bir sistemdir. Sidoma tarafından gerekli eğitimler açık ve anlaşılır verilmiştir. Kurulum sonrası her aşamada kullanımda gerekli destekleri alabildik. Sistem kullanımı için hazırlanan doküman ve videolar açıklayıcı ve kullanışlıdır.",
    image: "biruni.png",
  },
];

export default UserCommentsContent;