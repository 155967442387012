import React from "react";
import FlipCard from "../components/flipcard";

function FlipCardList() {
  return (
    <div className="mb-10">
        <div className="flex justify-center">
            <p className="font-extrabold text-5xl font-generalSans text-gray-700 md:text-7xl mb-20">ÜRÜNLERİMİZ</p>
        </div>
      <div className="flex flex-wrap justify-center gap-5">
        <FlipCard
          imageSrc="../qdmsBeyaz.png"
          description="Entegre Yönetim Sistemlerinde hedeflenen kalitenin gerçekleştirilmesi için geliştirilmiş, işinize dost bir yönetim sistemi çözümüdür."
        />
        <FlipCard
          imageSrc="../ebaBeyaz.png"
          description="Tüm kurum süreçlerinizi ve süreçlerinizin girdi-çıktıları olan dokümanlarınızı Bimser eBA ile dijital platformda etkin ve verimli yönetin."
        />
        <FlipCard
          imageSrc="../ensembleBeyaz.png"
          description="Süreçlerin modelleme faaliyetlerinin gerçekleştirilerek kurumsal mimarinin oluşturulmasının yanı sıra kurumun farklı perspektiflerden değerlendirilerek, stratejik plan takibi ve performans değerlendirmelerinin sağlanabildiği çözümdür."
        />
      </div>
    </div>
  );
}

export default FlipCardList;
