import React from "react";
import { IoMdMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import emailjs from "@emailjs/browser";
import SuccessPopup from "../components/successPopup";
import ErrorPopup from "../components/errorPopup";
import { useState } from "react";
import { AiFillInstagram, AiFillLinkedin } from "react-icons/ai";

const Contact = () => {
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  //email.js sitesinde
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        () => {
          setShowSuccessPopup(true);
        },
        () => {
          setShowErrorPopup(true);
        }
      );
    e.target.reset();
  };

  return (
    <div className="container mx-auto p-8 animate-fadeIn my-28">
      
      <h2 className="text-4xl font-semibold text-center mb-4">Bize Sorun</h2>
      <p className="text-center text-gray-600 mb-10">
        Bizimle iletişime geçmekten çekinmeyin! Sorularınızı buraya gönderin,
        sizi dinleyelim.
      </p>

      {/* Sosyal Medya Logoları */}
  <div className="flex justify-center gap-8 mb-10">
    <a
      href="https://www.instagram.com/sidoma.co/?igsh=MWFobGxtOHhocWN4MQ%3D%3D"
      target="_blank"
      rel="noopener noreferrer"
      className="bg-sidoma-blue text-white p-4 rounded-full shadow-lg transform transition duration-300 hover:scale-110 hover:shadow-2xl"
    >
      <AiFillInstagram className="text-3xl" />
    </a>
    <a
      href="https://www.linkedin.com/company/sidomaco/"
      target="_blank"
      rel="noopener noreferrer"
      className="bg-sidoma-blue text-white p-4 rounded-full shadow-lg transform transition duration-300 hover:scale-110 hover:shadow-2xl"
    >
      <AiFillLinkedin className="text-3xl" />
    </a>
  </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-10">
        {/* Call Us Section */}
        <div className="bg-white p-6 rounded-lg shadow-custom transform transition duration-300 hover:scale-105 ">
          <div className="flex items-center mb-4">
            <span className="bg-sidoma-blue text-white p-3 rounded-full">
              <FaPhoneAlt />
            </span>
            <div className="ml-4">
              <h3 className="text-lg font-semibold">Bizi Doğrudan Arayın</h3>
              <p className="md:text-4xl text-xl font-bold">535 395 06 55</p>
            </div>
          </div>
          <button
            className="bg-sidoma-blue text-white w-full py-2 rounded-md mt-4 hover:bg-gray-200"
            onClick={() => (window.location.href = "tel:+905353950655")}
          >
            Bizi Arayın
          </button>
        </div>

        {/* Email Section */}
        <div className="bg-white p-6 rounded-lg shadow-custom flex flex-col transform transition duration-300 hover:scale-105">
          <div className="flex items-center mb-4">
            <span className="bg-sidoma-blue text-white p-3 rounded-full">
              <IoMdMail />
            </span>
            <div className="ml-4">
              <h3 className="text-lg font-semibold">Ekibimizle Sohbet Edin</h3>
              <p className="md:text-2xl text-xl font-bold break-words md:mb-2">
                sidoma@sidoma.com.tr
              </p>
            </div>
          </div>
          <button
            className="bg-sidoma-blue text-white w-full py-2 rounded-md mt-4 hover:bg-gray-200"
            onClick={() =>
              (window.location.href = "mailto:sidoma@sidoma.com.tr")
            }
          >
            Bize Ulaşın
          </button>
        </div>
      </div>

      {/* Contact Form */}
      <div className="bg-white p-8 rounded-lg border shadow-custom">
        <form className="grid grid-cols-1 gap-6" onSubmit={sendEmail}>
          <div className="col-span-2">
            <label className="block text-gray-600">İsim</label>
            <input
              type="text"
              required
              name="name"
              placeholder="İsim"
              className="w-full p-3 border border-gray-300 rounded-lg mt-2"
            />
          </div>
          <div className="col-span-2">
            <label className="block text-gray-600">Email Addresi</label>
            <input
              type="email"
              required
              name="email"
              placeholder="Email Adresi"
              className="w-full p-3 border border-gray-300 rounded-lg mt-2"
            />
          </div>
          <div className="col-span-2 ">
            <label className="block text-gray-600">Telefon Numarası</label>
            <input
              type="text"
              required
              name="phone"
              placeholder="Telefon Numarası"
              className="w-full p-3 border border-gray-300 rounded-lg mt-2"
            />
          </div>
          <div className="col-span-2">
            <label className="block text-gray-600">Mesaj</label>
            <textarea
              name="message"
              required
              placeholder="Mesajınız..."
              className="w-full p-3 border border-gray-300 rounded-lg mt-2"
            ></textarea>
          </div>
          <div className="col-span-2">
            <button
              type="submit"
              className="w-full bg-red-400 hover:bg-red-500 text-white py-3 rounded-lg"
            >
              Gönder
            </button>
          </div>
        </form>
      </div>

      {/* Google Map */}
      <div className="mt-16">
        <iframe
          title="sidomaMap"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d376.26363526226794!2d29.015334155906192!3d41.02286939512368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab1357e9165c1%3A0xdc528a3f2da82c76!2zU2lkb21hIEXEn2l0aW0sIERhbsSxxZ9tYW5sxLFrIHZlIEJpbGnFn2lt!5e0!3m2!1str!2str!4v1732876749485!5m2!1str!2str"
          width="100%"
          height="450"
          allowFullScreen=""
          loading="lazy"
          className="rounded-lg shadow-md"
        ></iframe>
      </div>

      {showSuccessPopup && (
        <SuccessPopup onClose={() => setShowSuccessPopup(false)} />
      )}
      {showErrorPopup && (
        <ErrorPopup onClose={() => setShowErrorPopup(false)} />
      )}
    </div>
  );
};

export default Contact;
