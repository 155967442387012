import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import blogPosts from "../components/blogPosts";
import { Navigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

const BlogDetail = () => {
  const { id } = useParams();
  const post = blogPosts.find((post) => post.id === parseInt(id));
  const navigate = useNavigate();

  if (!post) {
    return <Navigate to="/blog" />;
  }

  return (
    <div className="min-h-screen mb-32">
      {/* RESİM KISMI */}
      
      <div className="w-full lg:h-[400px] h-[250px] overflow-hidden">
        <img src={post.image} alt="" className="w-full h-full object-cover" />
      </div>

      {/* YAZI KISMI */}
      <div className="sm:flex md:mt-20 mt-10 animate-fadeInUp">
        
        <div className="flex flex-col justify-start items-center gap-6 sm:w-1/3 mb-10">
        
          <img src={post.authorImage} alt="" className="rounded-full sm:size-44 size-32" />
          <h2 className="text-xl font-semibold">{post.author}</h2>
          <IoMdArrowRoundBack className="ml-4 size-8 md:mb-5" onClick={() => navigate(-1)}/> 
        </div>

        <div className="sm:w-2/3 md:mr-32 mx-7 ">
          <h1 className="text-3xl font-bold mb-4">{post.title}</h1>
          <p className="text-gray-600 mb-2">{post.date}</p>
          <div className=" " />
          {post.content}
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
