import React, { useState } from "react";
import { IoDocumentText } from "react-icons/io5";

const EnsembleContent = () => {
  const [content, setContent] = useState("surec");

  const handleButtonClick = (type) => {
    setContent(type);
  };

  const renderContent = () => {
    switch (content) {
      case "surec":
        return (
          <div>
            <h2 className="text-3xl font-bold text-gray-800 mb-6 sm:text-center">
              Süreç Yönetim Sistemi
            </h2>
            <p className="text-gray-700 mb-10">
              Süreç yönetimi, bir kurumun performansının sürekli olarak
              iyileştirilmesini sağlayan bir yönetim disiplinidir. Ensemble ile,
              süreçlerin geliştirilerek hedeflenen noktaya ulaşabilmesi için
              yapılması gereken modelleme, süreç performans değerlendirmesi ve
              iyileştirme faaliyetlerinin gerçekleştirilmesini sağlayan
              otomasyon uygulamasıdır.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Sol Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Norm Kadro Çalışmaları - Görev ve Sorumlulukların Tespiti
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Revizyon – Kontrol – Onay – Dağıtım ile Çalışanların
                    Katılımı
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Süreçler Arası Etkileşim Haritası – Uçtan Uca Süreç Yönetimi
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Esnek Görsel Tasarım</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Süreç Performans Takibi</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Kaynak Planlaması</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">İş Sürekliliği</span>
                </div>
              </div>

              {/* Sağ Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Süreçler ile Müşteri – Tedarikçi Etkileşimi
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Dağıtık Yapıların Merkezi Olarak Yönetilebilmesi
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    ISO 9001 Risk ve Fırsat Odaklı Süreç Yaklaşımı
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Verimli Kaynak Yönetimi</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    Süreç Olgunluk ve Etki Analizi
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    Risk ve Kontrol Yönetimi
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    Mevzuat Etkileşim Tablosu
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      case "Performans Yönetimi - Balanced Scorecard":
        return (
          <div>
            <h2 className="text-3xl font-bold text-gray-800 mb-6 sm:text-center">
              Performans Yönetimi – Balanced Scorecard
            </h2>
            <p className="text-gray-700 mb-10">
              Performans yönetimi ile kurumun finansal yapısı dahil olmak üzere
              tüm kaynakları ile geleceğe yönelik hedefleri doğrultusunda
              yönetilmesini ve takip edilmesini sağlayan sistemdir. Performans
              yönetim süreci, kurum paydaşlarının hedeflerine uygun şekilde
              ölçüldüğü ve objektif olarak değerlendirildiği bir çözüm olarak
              düşünülebilir. Ayrıca süreç içerisinde tüm organizasyonunun
              performansında iyileştirmeler hedeflenmektedir. “Kurumsal Karne
              (KK)” veya “Performans Karnesi” adlarıyla da bilinen “Balanced
              Scorecard (BSC)”, bir stratejik yönetim aracıdır. Finansal,
              Müşteri, Operasyon ve Öğrenme başlıklarından oluşan 4 perspektifi,
              şirketin stratejisine bütünsel ve tamamlayıcı bir yaklaşım sağlar.
              Amacı, şirket kaynaklarının ve hedeflerinin stratejiye göre
              planlanması ve düzenli olarak ölçümlenerek yürütülmesidir.
              Günümüzde birçok dev şirkette “Strateji belirleme ve stratejiyi
              uygulama” amacıyla kullanılmaktadır.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Sol Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Performans ve Stratejik Hedef Yönetimi
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Kurum, Lokasyon, Departman, Rol Bazında Performans Takibi
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    Rapor ve Grafik Gösterimi
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Kurumsal Karnelemenin Çıkarımı(BSC) – Farklı
                    Perspektiflerden Kurum Değerlendirmesi
                  </span>
                </div>
              </div>

              {/* Sağ Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Hedef Dışı Göstergelere Otomatik Aksiyon Takibi
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Kurumsal Sistemlerle Veri Entegrasyonu
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    Veri Girişi Uyarı Sistemi
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Gösterge Süreç İlişkisi</span>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return '';
    }
  };

  return (
    <div className="container mx-auto">
      {/* Butonlar - Ana Divin Dışında */}
      <div className="flex flex-wrap justify-start mb-4">
        <button
          className="bg-ensemble-turkuaz text-white px-4 py-2 hover:bg-ensemble-turkuaz-dark transition mb-2 duration-200"
          onClick={() => handleButtonClick("surec")}
        >
          Süreç Yönetim Sistemi
        </button>
        <button
          className="bg-ensemble-turkuaz-dark text-white px-4 py-2 hover:bg-ensemble-turkuaz transition mb-2 duration-200"
          onClick={() =>
            handleButtonClick("Performans Yönetimi - Balanced Scorecard")
          }
        >
          Performans Yönetimi – Balanced Scorecard
        </button>
      </div>

      {/* Ana İçerik Divi */}
      <div className="bg-white rounded-lg shadow-lg  border-t-2 p-6">
        {/* Açıklama Metni */}
        <div className="mt-6 text-gray-700 mb-10">{renderContent()}</div>
      </div>
    </div>
  );
};

export default EnsembleContent;
