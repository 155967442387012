import React, { useState } from "react";
import { IoDocumentText } from "react-icons/io5";

const EbaContent = () => {
  const [content, setContent] = useState("İş Süreçleri Otomasyonu");

  const handleButtonClick = (type) => {
    setContent(type);
  };

  const renderContent = () => {
    switch (content) {
      case "İş Süreçleri Otomasyonu":
        return (
          <div>
            <h2 className="text-3xl font-bold text-gray-800 mb-6 sm:text-center">
              İş Süreçleri Otomasyonu
            </h2>
            <p className="text-gray-700 mb-10">
              Çevikliğin öneminin gün geçtikçe arttığı günümüz koşullarında iş
              süreçlerinizi dijitale taşıyarak onaylarınızı Bimser eBA İş
              Süreçleri Otomasyonu ile verin, süreçlerinizi hızlandırın.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Sol Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">Çevik Akış Tasarımı</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Sektör/Departman Bağımsız Süreç Yönetimi
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Süreç Odaklı İş Yapma Modeli
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    Mobil Uygulama İle Kesintisiz İş Akışı
                  </span>
                </div>
              </div>

              {/* Sağ Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Erp Sistemleri İle Hızlı Ve Kolay Entegrasyon
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Multi Factor Authentication İle Daha Güvenli
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">Çoklu Dil Desteği</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Vekalet Alt Yapısı</span>
                </div>
              </div>
            </div>
          </div>
        );
      case "Doküman Yönetim Sistemi":
        return (
          <div>
            <h2 className="text-3xl font-bold text-gray-800 mb-6 sm:text-center">
              Doküman Yönetim Sistemi
            </h2>
            <p className="text-gray-700 mb-10">
              Kurumsal hafızanızı oluşturun veya düzenleyin. Kurumunuzun
              oluşturduğu bilgi, belge ve dokümanları Bimser eBA Doküman Yönetim
              Sistemi ile dijital platforma taşıyın. Güvenle saklayın,
              düzenleyin, onay verin ve istediğiniz zaman istediğiniz cihaz
              üzerinden erişim sağlayın.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Sol Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">Full Text Search</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">Etiketleme</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    Zengin Yetkilendirme Seçenekleri
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">Versiyonlama</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">Viewer</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">Elektronik İmza</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">Mobil İmza</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">Office 365</span>
                </div>
              </div>

              {/* Sağ Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">Office Add On</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">Sap Content</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Şifreli Saklama</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Dosya Sistemi Ya Da Veri Tabanı Üzerinde Saklama
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">DriveBA</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    Akıllı Sanal Klasör Yapısı
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Lokalizasyon Desteği</span>
                </div>
              </div>
            </div>
          </div>
        );
      case "Elektronik Belge Yönetim Sistemi (EBYS)":
        return (
          <div>
            <h2 className="text-3xl font-bold text-gray-800 mb-6 sm:text-center">
              Elektronik Belge Yönetim Sistemi (EBYS)
            </h2>
            <p className="text-gray-700 mb-10">
              Bimser eBA EBYS ile resmi yazışma usul ve esaslarına uygun(TS
              13298)bir şekilde belgelerinizi oluşturun ve delil niteliği
              taşıyan tüm belgelerinizi saklayın.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Sol Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Elektronik İmza Mobil İmza
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Kayıtlı Elektronik Posta (KEP)
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    Ulusal Elektronik Tebligat Sistemi (UETS)
                  </span>
                </div>
              </div>

              {/* Sağ Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Elektronik Yazışma Paketi (EYP)
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Standart Dosya Planı (SDP)
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      case "Sayısallaştırma Yönetimi":
        return (
          <div>
            <h2 className="text-3xl font-bold text-gray-800 mb-6 sm:text-center">
              Sayısallaştırma Yönetimi - Capture
            </h2>
            <p className="text-gray-700 mb-10">
              Dokümanlarınız ister fiziksel ortamda ister elektronik ortamda
              olsun Bimser eBA Capture ile sayısallaştırın ve kurumsal
              arşivinize aktarın.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Sol Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Doküman Tiplerini Belirleyin
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    İndeksleyin Ve Etiketleyin
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Günlük Doküman Operasyonlarınızı Hızlandırın
                  </span>
                </div>
              </div>

              {/* Sağ Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Taranan Dokümanlarınızı Kategorize Edin Ve Sınıflandırın
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                    Fiziksel Ya Da Dijital Dokümanlarınızı Arşivleyin
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      case "Dashboard":
        return (
          <div>
            <h2 className="text-3xl font-bold text-gray-800 mb-6 sm:text-center">
              Dashboard (Grafiksel Raporlama)
            </h2>
            <p className="text-gray-700 mb-10">
              Kurumsal uygulamalarınızdaki karmaşık verilerinizi Bimser eBA
              Dashboard ile grafiksel raporlarla analiz edin.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Sol Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                  Grafiksel Analiz
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                  Veri Konsolidasyonu
                  </span>
                </div>
                
              </div>

              {/* Sağ Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                  Etkileşimli Gösterim
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 min-w-[24px] min-h-[24px] mr-2" />
                  <span className="text-gray-700">
                  Hızlı Ve Kolay Rapor Tasarlama
                  </span>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return "null";
    }
  };

  return (
    <div className="container mx-auto">
      {/* Butonlar - Ana Divin Dışında */}
      <div className="flex flex-wrap justify-start mb-4">
        <button
          className="bg-eba-yellow text-white px-4 py-2 hover:bg-orange-300 transition mb-2 duration-200"
          onClick={() => handleButtonClick("İş Süreçleri Otomasyonu")}
        >
          İş Süreçleri Otomasyonu
        </button>
        <button
          className="bg-orange-400 text-white px-4 py-2 hover:bg-orange-300 transition mb-2 duration-200"
          onClick={() => handleButtonClick("Doküman Yönetim Sistemi")}
        >
          Doküman Yönetim Sistemi
        </button>
        <button
          className="bg-eba-yellow text-white px-4 py-2 hover:bg-orange-300 transition mb-2 duration-200"
          onClick={() =>
            handleButtonClick("Elektronik Belge Yönetim Sistemi (EBYS)")
          }
        >
          Elektronik Belge Yönetim Sistemi (EBYS)
        </button>
        <button
          className="bg-orange-400 text-white px-4 py-2 hover:bg-orange-300 transition mb-2 duration-200"
          onClick={() => handleButtonClick("Sayısallaştırma Yönetimi")}
        >
          Sayısallaştırma Yönetimi
        </button>
        <button
          className="bg-eba-yellow text-white px-4 py-2 hover:bg-orange-300 transition mb-2 duration-200"
          onClick={() => handleButtonClick("Dashboard")}
        >
          Dashboard
        </button>
      </div>

      {/* Ana İçerik Divi */}
      <div className="bg-white rounded-lg shadow-lg  border-t-2 p-6">
        {/* Açıklama Metni */}
        <div className="mt-6 text-gray-700 mb-10">{renderContent()}</div>
      </div>
    </div>
  );
};

export default EbaContent;
