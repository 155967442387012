import React from "react";
import { FaSyncAlt } from "react-icons/fa";
import { TbFileAnalytics } from "react-icons/tb";
import { MdManageAccounts,MdCastForEducation } from "react-icons/md";
import { PiStandardDefinitionFill } from "react-icons/pi";

const ServicesConsulting = () => {
  return (
    <div className="py-10 px-4 animate-fadeInUp">
      <div className="max-w-6xl mx-auto">
        {/* Hizmet Başlığı */}
        <div className="text-center mb-10 mt-20">
          <h1 className="text-3xl md:text-5xl font-bold text-gray-800 mb-4">
          Yönetim Sistemleri Danışmanlığı
          </h1>
          <p className="text-gray-600 text-lg">
          Firmanızı kurumsal bir seviyeye taşırken, kurumsallığın sürekliliğini sağlıyor, değişim yönetimini etkili bir şekilde kurguluyor ve günümüz için dijital dönüşüme hazır hale getiriyoruz.
          </p>
        </div>

        {/* İçerik ve Görsel Kısmı */}
        <div className="flex flex-col 1000:flex-row items-center bg-white rounded-lg shadow-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 sm:pt-0 pt-10">
          {/* Görsel */}
          <div className="w-full 1000:w-1/2 h-64 1000:h-auto">
            <img
              src="../consulting.jpg"
              alt="Service"
              className="w-full h-full object-cover"
            />
          </div>
          
          {/* Detaylar */}
          <div className="w-full 1000:w-1/2 p-6 1000:p-10">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Mükemmelliği Yakalamak
            </h2>
            <p className="text-gray-700 mb-4">
            Yönetim Sistemleri Danışmanlığı, işletmelerin süreçlerini optimize etmeye ve uluslararası standartlara uygunluk sağlamaya yönelik kapsamlı bir hizmettir. Bu danışmanlık hizmeti, işletmenin ihtiyaçlarına özel yönetim sistemleri geliştirmeyi, uygulanabilir çözümler sunmayı ve sürekli iyileştirme süreçlerini desteklemeyi amaçlar. 
            </p>
            <p className="text-gray-700 mb-4">
            Deneyimli danışmanlarımız, mevcut süreçleri değerlendirir, güçlü ve zayıf yönleri analiz eder ve işletmenizin hedeflerine ulaşmasına yardımcı olacak stratejiler geliştirir. Ayrıca, ISO gibi uluslararası standartlara uygunluk sağlamak için gerekli adımları atmanıza destek olur. Sonuç olarak, işletmenizin verimliliğini artırırken, maliyetleri düşürmeye ve müşteri memnuniyetini artırmaya yönelik somut sonuçlar elde etmenizi sağlar.
            </p>
            <div className="mt-6">
              <a href="/contact" className="bg-sidoma-blue text-white px-6 py-2 rounded-md shadow hover:bg-sidoma-white-hover transition duration-200">
                Bilgi Alın
              </a>
            </div>
          </div>
        </div>

        {/* Key Features */}
        <div className="mt-32">
          <h3 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Hizmet İçeriği</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                icon: <TbFileAnalytics className="text-sidoma-blue text-5xl" />,
                title: 'Kapsamlı Süreç Analizi',
                description: 'Mevcut iş süreçlerinizi detaylı bir şekilde değerlendirerek, güçlü ve zayıf yönlerinizi belirliyoruz. Bu analiz, işletmenizin performansını artıracak geliştirme alanlarını ortaya koyar.',
              },
              {
                icon: <MdManageAccounts className="text-sidoma-blue text-5xl" />,
                title: 'Özelleştirilmiş Yönetim Sistemleri',
                description: 'İşletmenizin özel ihtiyaçlarına uygun yönetim sistemleri tasarlıyoruz. Böylece, süreçlerinizi optimize ederken, sektörel gereksinimleri de göz önünde bulunduruyoruz.',
              },
              {
                icon: <PiStandardDefinitionFill className="text-sidoma-blue text-5xl" />,
                title: 'ISO ve Standartlara Uygunluk',
                description: 'Uluslararası standartlara (örneğin, ISO 9001) uygunluk sağlamanıza yardımcı olarak, işletmenizin uluslararası alanda rekabet gücünü artırıyoruz.',
              },
              {
                icon: <MdCastForEducation className="text-sidoma-blue text-5xl" />,
                title: 'Eğitim ve Bilgilendirme',
                description: 'Personelinizi yönetim sistemleri konusunda eğiterek, süreçlerin etkin bir şekilde uygulanmasını sağlıyoruz. Bu eğitimler, çalışanların sistemlere uyumunu artırır.',
              },
              {
                icon: <FaSyncAlt className="text-sidoma-blue text-4xl" />,
                title: 'Sürekli İyileştirme',
                description: 'Danışmanlık hizmetimiz, sürekli iyileştirme prensipleri doğrultusunda işletmenizin gelişimine odaklanır. Süreçlerinizi düzenli olarak gözden geçiriyor ve iyileştirme fırsatlarını değerlendiriyoruz.',
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg transform hover:-translate-y-2 transition duration-300 ease-in-out"
              >
                <div className="flex items-center justify-center mb-4">
                  {feature.icon}
                </div>
                <h4 className="text-xl font-semibold text-gray-800 mb-2 text-center">{feature.title}</h4>
                <p className="text-gray-600 text-center">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Diğer Hizmetler */}
        <div className="mt-32 mb-16">
          <h3 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Diğer Hizmetlerimiz</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[
              { title: "Yazılım Geliştirme", description: "İşinizi en iyi şekilde yansıtan, hızlı, güvenli ve kullanıcı dostu web siteleri tasarlıyoruz.", link: "/servicesDevelopment" },
              { title: "Sosyal Medya İçerik Üretimi", description: "Markanızın hikayesini etkileyici ve yaratıcı içeriklerle sosyal medyada öne çıkarıyoruz.", link: "/servicesSocialMedia" },
              { title: "Ürünlerimiz", description: "İşinizi zirveye taşıyacak, güçlü dijital çözümlerimiz.", link: "/products" },
            ].map((service, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-md transition-shadow duration-200">
                <h4 className="text-xl font-semibold text-gray-800 mb-2">{service.title}</h4>
                <p className="text-gray-600 mb-4">{service.description}</p>
                <a href={service.link} className="text-sidoma-blue hover:text-indigo-800 hover:underline transition-colors duration-200">
                  Daha Fazlası →
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesConsulting;