import React from 'react';
import CustomerRatio from '../components/customerRatio';
import UserComments from '../components/userComments';

const References = () => {
  return (
    <div className='min-h-screen md:my-20'>
      <CustomerRatio></CustomerRatio>
      <UserComments></UserComments>
    </div>
  );
};

export default References;