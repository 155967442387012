import React from "react";

const LandingPage = () => {
  return (
    <div className="mt-28 md:mb-24">
      <div className="flex-col items-center  animate-fadeIn">
        <p className="font-extrabold text-4xl text-gray-700  sm:text-7xl text-center mb-10 font-clashDisplay">
          GELECEĞİN İŞ DÜNYASI <br />
          İÇİN YENİLİKÇİ ÇÖZÜMLER
        </p>
        <div className="flex justify-center md:gap-6 gap-3 mb-6">
          <a
            href="/products"
            className="bg-white shadow-custom border-2 border-sidoma-blue text-sidoma-blue  py-2 px-6 font-bold hover:bg-sidoma-blue hover:text-white text-lg rounded-xl transition duration-200 ease-in-out"
          >
            Ürünlerimiz
          </a>
          <a
            href="/services"
            className="bg-sidoma-blue hover:bg-white text-white hover:text-sidoma-blue hover:border-2 border-sidoma-blue shadow-custom py-2 px-6 font-bold text-lg rounded-xl transition duration-200 ease-in-out"
          >
            Hizmetlerimiz
          </a>
        </div>
        <p className="text-center text-gray-500 mb-10 md:mb-20">
          <span className="font-clashDisplay">SİDOMA</span> Mükemmellik yolculuğundaki rehberiniz.
        </p>
        <div className="relative flex-col items-center">
          <div className="w-full h-36 md:h-96 overflow-hidden sm:px-28 px-8 relative">
            <img
              src="../workingDesk.jpg"
              alt=""
              className="w-full h-full object-cover rounded-xl"
            />
            <a
              href="/contact"
              className="absolute inset-x-0 bottom-4 lg:bottom-6 bg-red-500 hover:bg-white text-white hover:text-red-500 hover:border-2 border-red-400 shadow-custom py-2 px-6 font-bold text-lg rounded-xl transition duration-200 ease-in-out text-center mx-auto"
              style={{ width: "fit-content" }}
            >
              Bize Sorun
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
