import React from 'react';
import { IoShareSocialSharp } from "react-icons/io5";
import { VscServerProcess } from "react-icons/vsc";
import { FaPersonChalkboard } from "react-icons/fa6";
import { AiFillMessage } from "react-icons/ai";

const Services = () => {
  return (
    <div className="py-16 mb-24 animate-fadeInUp">
    {/* Üst Başlık Bölümü */}
    <div className="text-center mb-20 mt-20">
      <p className="text-xl text-gray-500">Hizmetlerimiz</p>
      <h2 className="md:text-5xl text-3xl  font-bold text-gray-900">Bizimle Birlikte <br /> Geliştirin, Yönetin, Dönüştürün</h2>
    </div>

    {/* Kartlar Bölümü */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mx-auto px-4 max-w-7xl">
      {/* Kartlar */}
      {[
        { title: "Yazılım Geliştirme", icon: <VscServerProcess />, desc: "İhtiyaçlarınıza özel tasarlanan web sitesi ve mobil uygulamalarla dijital dünyada fark yaratın!",link: "/servicesDevelopment" },
        { title: "Sosyal Medya İçerik Üretimi", icon:<IoShareSocialSharp /> , desc: "Markanızı büyüten etkili sosyal medya postlarıyla hedef kitlenize ulaşın!",link: "/servicesSocialMedia" },
        { title: "Yönetim Sistemleri Danışmanlığı", icon: <FaPersonChalkboard />, desc: "Firmanızı kurumsal bir seviyeye taşırken, kurumsallığın sürekliliğini sağlıyoruz.", link: "/servicesConsulting" },
      ].map((service, index) => (
        <div key={index} className="bg-white p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-200">
          <div className="mb-4">
            <div className="h-40 w-40 text-9xl rounded-full mx-auto flex items-center justify-center text-sidoma-blue">
              {service.icon}
            </div>
          </div>
          <h3 className="text-xl font-semibold text-gray-800">{service.title}</h3>
          <p className="text-gray-600 mb-6">{service.desc}</p>
          <a href={service.link} className="bg-sidoma-blue text-white px-4 py-2 rounded-md hover:bg-sidoma-white-hover hover:text-sidoma-blue transition-colors duration-200">Daha Fazla Bilgi</a>
        </div>
      ))}
    </div>

    {/* Alt İletişim Bölümü */}
    <div className="text-center mt-40 mx-3">
      <p className="text-xl text-gray-500">Bize Ulaşın</p>
      <h2 className="text-3xl font-bold text-gray-900 mb-6">Bizimle Çalışmaya Hazır Mısın?</h2>
      <div className="max-w-2xl mx-auto">
        <div className="bg-white p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-200">
          <div className="h-20 w-20  rounded-full mx-auto flex items-center justify-center text-red-400 mb-4">
            {/* İkon (Mesaj Gönder) */}
            <AiFillMessage className='text-6xl'></AiFillMessage>
          </div>
          <h3 className="text-xl font-semibold text-gray-800">Bize Ulaşın</h3>
          <p className="text-gray-600 mb-6">Ekibimizle ihtiyacınız olan hizmetler hakkında iletişime geçin.</p>
          <a href='/contact' className=" hover:text-red-400 px-4 py-2 rounded-md bg-red-400 hover:bg-red-200 text-white transition-colors duration-200 ">İletişim</a>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Services;