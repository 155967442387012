import React, { useState } from "react";
import BlogCard from "../components/blogCard";
import blogPosts from "../components/blogPosts";
import { FaSearch } from "react-icons/fa";

const Blog = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPosts, setFilteredPosts] = useState(blogPosts);

  const handleSearch = (e) => {
    e.preventDefault();
    const filtered = blogPosts.filter((post) =>
      post.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredPosts(filtered);
  };

  const handleButtonClick = (term) => {
    setSearchTerm(term);
    const filtered = blogPosts.filter((post) =>
      post.title.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredPosts(filtered);
  };

  return (
    <div className="flex flex-col min-h-screen my-20 mx-4 500:ml-32 500:mr-14">


      <div className="flex flex-col items-start w-full my-10 animate-fadeInUp">
        <div >
          <h2 className="text-3xl font-semibold mb-4 text-left ">
            Blog Yazıları
          </h2>
          <p className="mb-6 text-center">Blog sayfamıza hoşgeldiniz.</p>
        </div>
        <div className="mb-6 flex flex-col md:flex-row w-full">
          <form
            onSubmit={handleSearch}
            className="flex space-x-2 mb-4 md:mb-0 "
          >
            <input
              type="text"
              placeholder="Makaleleri aratın..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="py-2 pl-3 border border-gray-300 rounded w-64"
            />
            <button
              type="submit"
              className="text-sidoma-white hover:bg-sidoma-white-hover px-4 h-11 rounded-md text-lg bg-sidoma-blue transition duration-200 ease-in-out"
            >
              <FaSearch />
            </button>
          </form>
          <div className="flex space-x-2 md:w-full md:ml-6">
            <button
              onClick={() => handleButtonClick("QDMS")}
              className="text-sidoma-white hover:bg-orange-300 px-4 h-11 rounded-md text-lg bg-orange-400 transition duration-200 ease-in-out"
            >
              QDMS
            </button>
            <button
              onClick={() => handleButtonClick("")}
              className="text-sidoma-white hover:bg-yellow-200 px-4 h-11 rounded-md text-lg bg-yellow-300 transition duration-200 ease-in-out"
            >
              Tümü
            </button>
            <button
              onClick={() => handleButtonClick("EBA")}
              className="text-sidoma-white hover:bg-pink-200 px-4 h-11 rounded-md text-lg bg-pink-300 transition duration-200 ease-in-out"
            >
              EBA
            </button>
          </div>
        </div>
      </div>


      <div className="flex flex-wrap justify-center gap-10 md:justify-start  animate-fadeInUp ">
        <BlogCard posts={filteredPosts} />
      </div>
    </div>
  );
};

export default Blog;
