import React from 'react';
import { AiFillMessage } from "react-icons/ai";

const Products = () => {
  return (
    <div className="pt-16  animate-fadeInUp">
    {/* Üst Başlık Bölümü */}
    <div className="text-center mb-20 mt-20">
      <p className="text-xl text-gray-500">Ürünlerimiz</p>
      <h2 className="md:text-5xl text-3xl  font-bold text-gray-900">Dijital Çözümlerle  <br /> İşinizi Geleceğe Taşıyoruz.</h2>
    </div>

    {/* Kartlar Bölümü */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mx-auto px-4 max-w-7xl">
      {/* Kartlar */}
      {[
        { title: "QDMS", image:"../qdmsRedText.png", desc: "Entegre Yönetim Sistemleri ile tüm işlerinizi planlı ve etkili yönetin.",link: "/ProductsQdms" },
        { title: "Ensemble", image:"../EnsembleTurkuvaz.png" , desc: "Süreç modelleme, stratejik plan takibi ve performans değerlendirmesi sağlar.",link: "/productsEnsemble" },
        { title: "eBA", image: "../eBAYellow.png", desc: "Tüm kurum süreçlerinizi ve dokümanlarınızı Bimser eBA ile dijitalde yönetin.", link: "/productsEba" },
      ].map((product, index) => (
        <div key={index} className="bg-white p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-200">
          <div className="mb-4">
            <img src={product.image} alt='abc' className="h-40 w-40 text-9xl  mx-auto flex items-center justify-center " />
          </div>
          <h3 className="text-xl font-semibold text-gray-800">{product.title}</h3>
          <p className="text-gray-600 mb-6">{product.desc}</p>
          <a href={product.link} className="bg-sidoma-blue text-white px-4 py-2 rounded-md hover:bg-sidoma-white-hover hover:text-sidoma-blue transition-colors duration-200">Daha Fazla Bilgi</a>
        </div>
      ))}
    </div>

    {/* Alt İletişim Bölümü */}
    <div className="text-center my-40 mx-3">
      <p className="text-xl text-gray-500">Bize Ulaşın</p>
      <h2 className="text-3xl font-bold text-gray-900 mb-6">Bizimle Çalışmaya Hazır Mısın?</h2>
      <div className="max-w-2xl mx-auto">
        <div className="bg-white p-6 rounded-lg shadow-md text-center hover:shadow-xl transition-shadow duration-200">
          <div className="h-20 w-20  rounded-full mx-auto flex items-center justify-center text-red-400 mb-4">
            {/* İkon (Mesaj Gönder) */}
            <AiFillMessage className='text-6xl'></AiFillMessage>
          </div>
          <h3 className="text-xl font-semibold text-gray-800">Bize Ulaşın</h3>
          <p className="text-gray-600 mb-6">Ekibimizle ihtiyacınız olan hizmetler hakkında iletişime geçin.</p>
          <a href='/contact' className=" hover:text-red-400 px-4 py-2 rounded-md bg-red-400 hover:bg-red-200 text-white transition-colors duration-200 ">İletişim</a>
        </div>
      </div>
    </div>

    <a href="/contact">
    <div className="relative overflow-hidden bg-gradient-to-r from-yellow-400 to-orange-400 py-2 sm:py-3 md:py-4">
      <div className="whitespace-nowrap animate-scroll">
        <span className="mx-4 text-lg sm:text-xl md:text-2xl font-bold text-white">
          DEMO TALEBİNDE BULUNUN &nbsp;&nbsp;&nbsp; DEMO TALEBİNDE BULUNUN&nbsp;&nbsp;&nbsp; DEMO TALEBİNDE BULUNUN &nbsp;&nbsp;&nbsp; DEMO TALEBİNDE BULUNUN &nbsp;&nbsp;&nbsp;  DEMO TALEBİNDE BULUNUN &nbsp;&nbsp;&nbsp;  DEMO TALEBİNDE BULUNUN
        </span>
      </div>
    </div>
    </a>

    
  </div>
  
  );
};

export default Products;