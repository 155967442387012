import React from 'react';

function HomeProductsServices() {
  return (
    <section className="flex flex-col items-center bg-[#f8f4ec] py-12 px-4 md:px-8 lg:px-16">
      {/* Üst Başlık */}
      <p className="text-center text-gray-600 text-sm mb-2">Ne yapıyoruz?</p>
      <h2 className="text-center text-2xl md:text-3xl lg:text-4xl font-semibold mb-10">
      Zirveye Giden Yolda Kaliteyle
      </h2>

      {/* Kartlar Bölümü */}
      <div className="flex flex-col lg:flex-row gap-6 w-full max-w-4xl">
        {/* Kart 1 - Start my business */}
        <div className="flex flex-col items-start bg-white rounded-xl p-6 shadow-md w-full lg:w-1/2 transform transition-all duration-300 hover:scale-105 hover:shadow-lg">
          <h3 className="text-xl font-semibold text-sidoma-blue mb-3">Ürünlerimiz</h3>
          <p className="text-gray-700 mb-6">
          Ürünlerimiz, kuruluşların kalite, performans, süreç ve iş akış yönetiminde dijital dönüşümü kolaylaştırarak operasyonel verimlilik sağlar.  
          </p>
          <div className="flex gap-3">
            <a href='/products' className="border border-sidoma-blue text-sidoma-blue rounded-full px-4 py-1 text-sm transform transition-colors duration-300 hover:bg-sidoma-blue hover:text-white">
              
              Detaylı Bilgi
            </a>
            <a href='/contact' className="border border-red-400 text-red-400 rounded-full px-4 py-1 text-sm transform transition-colors duration-300 hover:bg-red-400 hover:text-white">
              İletişim
            </a>
          </div>
        </div>

        {/* Kart 2 - Switch to Osome */}
        <div className="flex flex-col items-start bg-white rounded-xl p-6 shadow-md w-full lg:w-1/2 transform transition-all duration-300 hover:scale-105 hover:shadow-lg">
          <h3 className="text-xl font-semibold text-sidoma-blue mb-3">Hizmetlerimiz</h3>
          <p className="text-gray-700 mb-6">
          Hizmetlerimiz, işletmenizin ihtiyaç duyduğu dijital dönüşümü ve kurumsal büyümeyi desteklemek amacıyla tasarlanmıştır. 
          </p>
          <div className="flex gap-3">
            <a href='/services' className="border border-sidoma-blue text-sidoma-blue rounded-full px-4 py-1 text-sm transform transition-colors duration-300 hover:bg-sidoma-blue hover:text-white">
            Detaylı Bilgi
            </a>
            <a href='/contact' className="border border-red-400 text-red-400 rounded-full px-4 py-1 text-sm transform transition-colors duration-300 hover:bg-red-400 hover:text-white">
            İletişim
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeProductsServices;