import React from 'react';
import { TbFileAnalytics } from 'react-icons/tb';
import { MdManageAccounts, MdCastForEducation } from 'react-icons/md';
import { PiStandardDefinitionFill } from 'react-icons/pi';
import { FaSyncAlt } from 'react-icons/fa';
import Features from './Features.jsx';

const QdmsFeatures = () => {
  const features = [
    {
      icon: <TbFileAnalytics className="text-qdms text-5xl" />,
      title: "Verimlilik Artışı",
      description: "Süreçlerinizi optimize ederek zaman tasarrufu sağlar.",
    },
    {
      icon: <MdManageAccounts className="text-qdms text-5xl" />,
      title: "Kalite Kontrolü",
      description: "Hataları minimize ederek ürün kalitesini artırır.",
    },
    {
      icon: <PiStandardDefinitionFill className="text-qdms text-5xl" />,
      title: "İzlenebilirlik",
      description: "Tüm süreçlerinizi takip edilebilir hale getirir.",
    },
    {
      icon: <MdCastForEducation className="text-qdms text-5xl" />,
      title: "Dokümantasyon Yönetimi",
      description: "Belgelerinizi merkezi bir sistemde kolayca yönetir.",
    },
    {
      icon: <FaSyncAlt className="text-qdms text-4xl" />,
      title: "Uygunluk Sağlama",
      description: "Standartlara ve düzenlemelere uyumu kolaylaştırır.",
    },
    {
      icon: <FaSyncAlt className="text-qdms text-4xl" />,
      title: "Etkileşimli Raporlama",
      description: "Veri analizi ile karar verme süreçlerini destekler.",
    },
  ];

  return (
    <div>
      <Features features={features} />
    </div>
  );
};

export default QdmsFeatures;