import React from "react";


const Features = ({ features }) => {
  return (
    <div className="my-32">
      <h3 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
        Özellikler
      </h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {features.map((feature, index) => (
          <div
            key={index}
            className="bg-white p-6 rounded-lg shadow-lg transform hover:-translate-y-2 transition duration-300 ease-in-out"
          >
            <div className="flex items-center justify-center mb-4">
              {feature.icon}
            </div>
            <h4 className="text-xl font-semibold text-gray-800 mb-2 text-center">
              {feature.title}
            </h4>
            <p className="text-gray-600 text-center">
              {feature.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;