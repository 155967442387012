import React from 'react';
import { TbFileAnalytics } from 'react-icons/tb';
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { PiTreeStructureFill } from "react-icons/pi";
import { LuBrainCircuit } from "react-icons/lu";
import { FaSyncAlt } from 'react-icons/fa';
import { IoIosNotifications } from "react-icons/io";
import Features from './Features.jsx';

const EbaFeatures = () => {
  const features = [
    {
      icon: <IoIosNotifications  className="text-eba-yellow text-5xl" />,
      title: "Kolay Tasarım",
      description: "Süreçlerinizi sürükle bırak tasarlayın.",
    },
    {
      icon: <PiTreeStructureFill className="text-eba-yellow text-5xl" />,
      title: "Sektörden Bağımsız ",
      description: "Kurumunuz hangi sektörde olursa olsun tüm departmanların operasyonel süreçlerini dijital ortamda entegre bir şekilde yönetin.",
    },
    {
      icon: <FaMoneyBillTrendUp  className="text-eba-yellow text-5xl" />,
      title: "Görsel Raporlar",
      description: "Verileri görsel raporlara dönüştürün.",
    },
    {
      icon: <TbFileAnalytics className="text-eba-yellow text-5xl" />,
      title: "Süreç Takibi",
      description: "İş süreçlerinizi standart hale getirerek süreçlerinizi takip edin ve süreç maliyetlerinizi azaltın.",
    },
    {
      icon: <LuBrainCircuit className="text-eba-yellow text-4xl" />,
      title: "Kurumsal Hafızanın Oluşturulması",
      description: "Bilgi ve belgeleri merkezi bir platformda saklayın.",
    },
    {
      icon: <FaSyncAlt className="text-eba-yellow text-4xl" />,
      title: "ERP Sistemleri ve Kurumsal Uygulamalar ile Entegrasyon",
      description: "İş akışlarınız ile kurumsal uygulamalarınızı hızlıca ve kolayca entegre edin.",
    },
  ];

  return (
    <div>
      <Features features={features} />
    </div>
  );
};

export default EbaFeatures;