import React, { useState } from "react";
import { IoDocumentText } from "react-icons/io5";

const QdmsContent = () => {
  const [content, setContent] = useState("entegrasyon");

  const handleButtonClick = (type) => {
    setContent(type);
  };

  const renderContent = () => {
    switch (content) {
      case "entegrasyon":
        return (
          <div>
            <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
              Entegre Yönetim Sistemi
            </h2>
            <p className="text-gray-700 mb-10">
              QDMS Entegre Yönetim Sistemi çözümü ile birlikte dokümanlarınızın
              yaşam döngüsü uçtan uca çok rahat bir şekilde yönetmeniz
              mümkündür. Revizyonlar, gözden geçirmeler ve okuma görevlerinin
              takibi artık sizin için problem olmaktan çıkar. Bunun yanında
              kronikleşmiş problemlerin çözümü için DİF modülü kalıcı çözümlerin
              planlaması adına size yardımcı olacaktır. Aksiyon modülü özelinde
              günlük işlerinizi ve toplantı maddelerinin planlamasını
              sağlayabilirsiniz. Denetim modülünde çeşitli denetim tiplerinizden
              çıkan bulguların takibini efektif bir şekilde yapmanız mümkündür.
              Öneri ve Anket modülleri ile çalışanların katılımını ve aidiyetini
              arttırmanız mümkündür. Tüm bu süreçlerde ve firma içindeki diğer
              süreçler için risklerin tespit edilip risk seviyelerinin
              çıkartılması da oldukça kritik bir husus olup QDMS Risk Yönetim
              modülleri bu konuda size rehber olacaktır.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Sol Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Doküman Yönetimi</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Müşteri Şikayetleri</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Denetim Yönetimi</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Eğitim Planlama</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Öneri</span>
                </div>
              </div>

              {/* Sağ Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Dif Yönetimi</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Aksiyon Planlama</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Risk Yönetimi</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Anket</span>
                </div>
              </div>
            </div>
          </div>
        );
      case "kalite":
        return (
          <div>
            <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
              Kalite Yönetim Sistemi
            </h2>
            <p className="text-gray-700 mb-10">
              Firmaya ait Kalite Yönetim Standardının işletilmesi ve tüm
              paydaşlar tarafından bir kurum kültürü haline getirilmesi hususu
              oldukça kıymetlidir. Kalitenin standardını sağlamak ve sürekli
              iyileştirmeye odaklanmak için nitelikli iş gücüne sahip olmak
              kadar kullanılacak materyallerin de önemi sizi rekabette öne
              geçireceği kadar müşterilerinize cevap verme hızında etkili
              olmakta. QDMS bu konuda size sunduğu çözüm haritasında tüm
              işlemlerinizi birbiri ile entegreli bir şekilde yönetmenizi mümkün
              kılar. Müşteri Geri Bildirimlerinizi sistematik olarak müşteri
              şikayetleri modüllerinde yönetip, memnuniyet düzeyinizin yüksek
              olmasını sağlayabilirsiniz. Bunun yanı sıra onaylı tedarikçi
              listenizi çıkartıp, hizmet kalitelerini arttırmak için Tedarikçi
              Değerlendirme Modülü size yol gösterecektir.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Sol Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Doküman Modülü</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    İç – Dış Müşteri Şikayetleri
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    Düzeltici ve İyileştirici Faaliyetler
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Denetim Planlama</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    Cihaz Yönetimi (Kalibrasyon)
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Risk Yönetimi</span>
                </div>
              </div>

              {/* Sağ Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Yasal Mevzuat Uyum</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Tedarikçi Değerlendirme</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Öneri</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Anket</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Eğitim</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Aksiyon Planlama</span>
                </div>
              </div>
            </div>
          </div>
        );
      case "cevre":
        return (
          <div>
            <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
              Çevre ve İş Sağlığı
            </h2>
            <p className="text-gray-700 mb-10">
              Çevre, sağlık ve güvenlik konularını entegre bir şekilde yönetilip
              raporlanması sonucu oluşan sistemler bütünü olarak
              tanımlayabiliriz. EHS kısaltması olarak geçer. Çünkü bir bakıma bu
              3 tanım birbiri ile oldukça iç içe geçmiş bir durumdadır. Çevreyi
              etkileyen bir hususun insan sağlığına zararsız olması pek
              düşünülemez.EHS yasa ve yükümlerine uyulması ve tüm çalışanlar
              tarafından bu kuralların benimsenmesi, firmaların hem
              aksiyonlarını yürütebilmeleri hem de dışarıya karşı olan prestij
              ve duyarlılıkları açısından oldukça kritiktir. Güvenli olmayan
              durumların bildirilmesi, çalışacak kısma ve işe göre KKD zimmet
              işlemlerinin yapılması, acil durumlara hazırlıklı olunması,
              çevreye karşı duyarlılık kapsamındaki işlemlerin planlanabilmesi
              QDMS ortamının sunduğu esnek altyapı ile oldukça kolaydır.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Sol Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">İsg Risk Değerlendirme</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">İş Kazası ve Ramak Kala</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Saha Denetimi</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    İş başı ve Periyodik Muayene
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Eğitim</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    Kişisel Koruyucu Donanım
                  </span>
                </div>
              </div>

              {/* Sağ Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">İş İzni Yönetimi</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    Acil Durum ve Tatbikat Yönetimi
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    Çevre Boyutları ve Analizi
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">MSDS Yönetimi</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                    Fonksiyonel Veri Toplama (Sera Gazı)
                  </span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Atık Yönetimi</span>
                </div>
              </div>
            </div>
          </div>
        );
      case "bilgi":
        return (
          <div>
            <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
              Bilgi Güvenliği
            </h2>
            <p className="text-gray-700 mb-10">
              Bilgi, yönetilmesi ve korunması gereken en değerli hazinedir.
              Günümüzde artan siber saldırılar ve çeşitli güvenlik zafiyetleri
              sonucu oluşan istenmeyen durumlar kurumların kesintisiz
              çalışmalarına engel olmakta ve daha önemlisi müşteri tarafından
              doğan güven kaybı telafisi zor bir duruma gelmektedir. Bu sebeple
              kurumun risk haritalarının belirlenmesi, güçlü ve zayıf yönlerinin
              tespit edilmesi, olası bir kritik durumda nasıl davranılacağının
              planlanması tüm kurumlar için çok hassas bir konu haline geldi.
              QDMS’in sunduğu bu çözüm ile birlikte kurumunuzun bilgi güvenliği
              açısından bir röntgenini çekebilirsiniz. Bu konuda farkındalığı
              arttırmak kurumun geleceği açısından önemli bir noktadır.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Sol Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Bilgi Güvenliği Risk ve Varlık Yönetimi</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Bilgi Güvenliği İhlal Bildirimi</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Denetim Planlama</span>
                </div>
              </div>

              {/* Sağ Kol */}
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">Düzeltici ve İyileştirici Faaliyetler</span>
                </div>
                <div className="flex items-center">
                  <IoDocumentText className="w-6 h-6 mr-2" />
                  <span className="text-gray-700">
                  Aksiyon Planlama
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto">
      {/* Butonlar - Ana Divin Dışında */}
      <div className="flex flex-wrap justify-start mb-4">
        <button
          className="bg-qdms text-white px-4 py-2 hover:bg-qdms-light transition mb-2 duration-200"
          onClick={() => handleButtonClick("entegrasyon")}
        >
          Entegre Yönetim Sistemi
        </button>
        <button
          className="bg-qdms-light text-white px-4 py-2 hover:bg-qdms transition mb-2 duration-200"
          onClick={() => handleButtonClick("kalite")}
        >
          Kalite Yönetim Sistemi
        </button>
        <button
          className="bg-qdms text-white px-4 py-2 hover:bg-qdms-light transition mb-2 duration-200"
          onClick={() => handleButtonClick("cevre")}
        >
          Çevre ve İş Sağlığı
        </button>
        <button
          className="bg-qdms-light text-white px-4 py-2 hover:bg-qdms transition mb-2 duration-200"
          onClick={() => handleButtonClick("bilgi")}
        >
          Bilgi Güvenliği
        </button>
      </div>

      {/* Ana İçerik Divi */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        {/* Açıklama Metni */}
        <div className="mt-6 text-gray-700 mb-10">{renderContent()}</div>
      </div>
    </div>
  );
};

export default QdmsContent;
