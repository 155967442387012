import React from 'react';

const HomepageBanner = () => {
  return (
    <div className="relative flex items-center justify-center h-[400px] md:h-[600px] bg-gradient-to-r from-black to-sidoma-blue overflow-hidden">
      {/* Arka Plan Görseli */}
      <img
        src="/retroComputer.jpg"
        alt="Background"
        className="absolute inset-0 w-full h-full object-cover opacity-50"
      />

      {/* İçerik */}
      <div className="relative z-10 text-center text-white p-4">
        <h1 className="text-3xl md:text-5xl font-bold mb-4 animate-fadeInDown">
          Rekabeti Kazandıran Dijital Güç
        </h1>
        <p className="text-md md:text-lg mb-6 animate-fadeInUp">
        Sonsuz olasılıkları ve heyecan verici fırsatları keşfedin.

        </p>
        
        {/* Buton */}
        <a href='/contact' className="px-6 py-3 text-lg font-semibold bg-sidoma-blue hover:bg-blue-700 rounded-md shadow-md transition-all duration-300 ease-in-out transform hover:scale-105 animate-bounce">
          Bize Katıl
        </a>
      </div>

      {/* Animasyonlu Daireler */}
      <div className="absolute top-10 left-10 w-32 h-32 bg-white opacity-20 rounded-full animate-pulse"></div>
      <div className="absolute bottom-20 right-20 w-40 h-40 bg-white opacity-20 rounded-full animate-pulse"></div>
    </div>
  );
};

export default HomepageBanner;
