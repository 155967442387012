import React from "react";
import UserCommentsContent from "./userCommentsContent";

const UserComments = () => {
  return (
    <div className="flex flex-col items-center justify-center p-8  my-20 py-20 ">
      {/* Başlık */}
      <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 text-center mb-2 ">
        Bizi Kullanıcılarımızdan Dinleyin
      </h2>
      <p className="text-center text-gray-600 max-w-xl mb-8 sm:mb-16">
        Kullanıcı Topluluğumuz Tarafından Paylaşılan İlham Verici Hikayeler ve
        Değerli Görüşler
      </p>

      {/* Kartlar */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-5xl">
        {UserCommentsContent.map((testimonial, index) => (
          <div
            key={index}
            className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300"
          >
            <div className="flex items-center mb-4">
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="w-12 h-12 rounded-full mr-4"
              />
              <div>
                <h3 className="font-semibold text-gray-800">
                  {testimonial.name}
                </h3>
                <p className="text-sm text-gray-500">{testimonial.title}</p>
              </div>
            </div>
            <p className="text-gray-700 text-sm">{testimonial.feedback}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserComments;
