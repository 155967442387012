import React from "react";

const SuccessPopup = ({ onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-10 rounded-lg shadow-lg text-center">
      <p className="text-green-600 font-bold md:text-xl">Mesajınız başarıyla gönderildi!</p>
      <button
        onClick={onClose}
        className="mt-6 px-6 py-1 bg-green-600 text-white rounded hover:bg-green-700"
      >
        Kapat
      </button>
    </div>
  </div>
);

export default SuccessPopup;