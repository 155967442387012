import React from 'react';
import HomepageBanner from '../components/homepageBanner';
import LandingPage from '../components/landingPage';
import FlipCardList from '../components/flipcardList';
import CustomerCommentCarousel from '../components/customerCommentCarousel';
import HomeProductsServices from '../components/HomeProductsServices';


const Home = () => {
  return (
    <div className='flex-col flex gap-32 bg-sidoma-white'>
      <LandingPage />
      <HomeProductsServices/>
      <FlipCardList />
      <HomepageBanner />
      <CustomerCommentCarousel />
    </div>
  );
};

export default Home;