import React from "react";
import EnsembleContent from "../components/EnsembleContent";
import EnsembleFeatures from "../components/EnsembleFeatures";

const ProductsEnsemble = () => {
  return (
    <div className="py-10 px-4 animate-fadeInUp">
      <div className="max-w-6xl mx-auto">
        {/* Hizmet Başlığı */}
        <div className="text-center mb-10 mt-20">
          <h1 className="text-3xl md:text-5xl font-bold text-gray-800 mb-4">
            ENSEMBLE
          </h1>
          <p className="text-gray-600 text-lg">
          Ensemble Süreç Yönetimi, Performans Yönetimi ve Balanced Scorecard
          </p>
        </div>
        {/* İçerik ve Görsel Kısmı */}
        <div className="flex flex-col md:flex-row items-center bg-white rounded-lg shadow-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 sm:pt-0 pt-10">
          {/* Görsel */}
          <div className="w-full md:w-1/3 h-64 md:h-auto">
            <img
              src="../EnsembleTurkuvaz.png"
              alt="Service"
              className="w-full h-full object-cover"
            />
          </div>
          {/* Detaylar */}
          <div className="w-full md:w-2/3 p-6 md:p-10">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Ensemble Süreç Yönetimi, Performans Yönetimi ve Balanced Scorecard
            </h2>
            <p className="text-gray-700 mb-4">
            Süreçlerin modelleme faaliyetlerinin gerçekleştirilerek kurumsal
            mimarinin oluşturulmasının yanı sıra kurumun farklı perspektiflerden
            değerlendirilerek, stratejik plan takibi ve performans
            değerlendirmelerinin sağlanabildiği çözümdür.
            </p>
            <div className="mt-6">
              <a
                href="/contact"
                className="bg-ensemble-turkuaz text-white px-6 py-2 rounded-md shadow hover:bg-sidoma-white-hover transition duration-200"
              >
                Bilgi Alın
              </a>
            </div>
          </div>
        </div>
        {/* özellikler */}
        <EnsembleFeatures />
        {/* modüller */}
        <EnsembleContent />
        {/* Diğer Hizmetler */}
        <div className="mt-32 mb-16">
          <h3 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
            Diğer Ürünlerimiz
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[
              {
                title: "QDMS",
                desc: "Entegre Yönetim Sistemleri ile tüm işlerinizi planlı ve etkili yönetin.",
                link: "/productsQdms",
              },
              {
                title: "Eba",
                desc: "Tüm kurum süreçlerinizi ve dokümanlarınızı Bimser eBA ile dijitalde yönetin.",
                link: "/productsEba",
              },
              {
                title: "Hizmetlerimiz",
                desc: "Bizimle Birlikte Geliştirin, Yönetin, Dönüştürün.",
                link: "/Services",
              },
            ].map((service, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg hover:shadow-md transition-shadow duration-200"
              >
                <h4 className="text-xl font-semibold text-gray-800 mb-2">
                  {service.title}
                </h4>
                <p className="text-gray-600 mb-4">{service.desc}</p>
                <a
                  href={service.link}
                  className="text-sidoma-blue hover:text-indigo-800 hover:underline transition-colors duration-200"
                >
                  Daha Fazlası →
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsEnsemble;
