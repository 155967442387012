import React from "react";
import { FaPaintbrush } from "react-icons/fa6";
import { TbDeviceAnalytics, TbSeo } from "react-icons/tb";
import { PiUsersThreeFill } from "react-icons/pi";

const ServicesDevelopment = () => {
  return (
    <div className="py-10 px-4 animate-fadeInUp">
      <div className="max-w-6xl mx-auto">
        {/* Hizmet Başlığı */}
        <div className="text-center mb-10 mt-20">
          <h1 className="text-3xl md:text-5xl font-bold text-gray-800 mb-4">
            Yazılım Geliştirme
          </h1>
          <p className="text-gray-600 text-lg">
          İşinizi en iyi şekilde yansıtan, hızlı, güvenli ve kullanıcı dostu web siteleri tasarlıyoruz.
          </p>
        </div>

        {/* İçerik ve Görsel Kısmı */}
        <div className="flex flex-col 1000:flex-row items-center bg-white rounded-lg shadow-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 sm:pt-0 pt-10">
          {/* Görsel */}
          <div className="w-full 1000:w-1/2 h-64 1000:h-auto">
            <img
              src="../devImage.jpg"
              alt="Service"
              className="w-full h-full object-cover"
            />
          </div>
          
          {/* Detaylar */}
          <div className="w-full 1000:w-1/2 p-6 1000:p-10">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Web Sitesi Geliştirme Hizmetlerimiz
            </h2>
            <p className="text-gray-700 mb-4">
            İşinizi dijital dünyaya taşımak veya çevrimiçi varlığınızı güçlendirmek mi istiyorsunuz? Profesyonel web sitesi geliştirme hizmetimizle, ihtiyaçlarınıza uygun, şık ve işlevsel bir web sitesi oluşturarak markanızı en iyi şekilde temsil etmenizi sağlıyoruz. 
            </p>
            <p className="text-gray-700 mb-4">
            Sunduğumuz hizmetlerle, yalnızca estetik ve kullanıcı dostu bir tasarım sağlamakla kalmıyor, aynı zamanda iş hedeflerinize ulaşmanıza yardımcı olacak çözümler sunuyoruz.
            </p>
            <div className="mt-6">
              <a href="/contact" className="bg-sidoma-blue text-white px-6 py-2 rounded-md shadow hover:bg-sidoma-white-hover transition duration-200">
                Bilgi Alın
              </a>
            </div>
          </div>
        </div>

        {/* Key Features */}
        <div className="mt-32">
          <h3 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Hizmet İçeriği</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 ">
            {[
              {
                icon: <FaPaintbrush className="text-sidoma-blue text-4xl" />,
                title: 'Özgün Tasarım',
                description: 'Müşterilerimizin kimliğini ve değerlerini yansıtan, özgün ve modern tasarımlar oluşturuyoruz. Mobil uyumlu (responsive) tasarımlar sayesinde, siteniz tüm cihazlarda mükemmel bir kullanıcı deneyimi sunar.',
              },
              {
                icon: <TbDeviceAnalytics className="text-sidoma-blue text-4xl" />,
                title: 'Kapsamlı İhtiyaç Analizi',
                description: 'Web sitesi projenizin ilk adımı olarak, işinizi ve hedef kitlenizi anlamak için kapsamlı bir ihtiyaç analizi yapıyoruz. Bu sayede, işinizi en iyi yansıtan ve hedef kitlenizin ilgisini çeken bir web sitesi oluşturabiliyoruz.',
              },
              {
                icon: <PiUsersThreeFill className="text-sidoma-blue text-4xl" />,
                title: 'Kullanıcı Deneyimi (UX) Odaklı Yapı',
                description: 'Web sitenizi, kullanıcıların kolayca gezinmesini ve istedikleri bilgilere rahatça ulaşmasını sağlayacak şekilde tasarlıyoruz. Kullanıcı odaklı bir yapı sayesinde ziyaretçilerinizin sitede daha uzun süre vakit geçirmelerini ve dönüşüm oranlarını artırmayı hedefliyoruz.',
              },
              {
                icon: <TbSeo className="text-sidoma-blue text-5xl" />,
                title: 'SEO ve Hız Optimizasyonu',
                description: 'Arama motoru optimizasyonu (SEO) ve hız optimizasyonu, web sitenizin performansı ve görünürlüğü için kritik öneme sahiptir. Web sitenizin hızlı açılmasını ve arama motorlarında üst sıralarda yer almasını sağlamak için en iyi uygulamaları takip ediyoruz.',
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg transform hover:-translate-y-2 transition duration-300 ease-in-out"
              >
                <div className="flex items-center justify-center mb-4">
                  {feature.icon}
                </div>
                <h4 className="text-xl font-semibold text-gray-800 mb-2 text-center">{feature.title}</h4>
                <p className="text-gray-600 text-center">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Diğer Hizmetler */}
        <div className="mt-32 mb-16">
          <h3 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Diğer Hizmetlerimiz</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[
              { title: "Sosyal Medya İçerik Üretimi", description: "Markanızın hikayesini etkileyici ve yaratıcı içeriklerle sosyal medyada öne çıkarıyoruz.", link: "/servicesSocialMedia" },
              { title: "Yönetim Sistemleri Danışmanlığı", description: "Firmanızı kurumsal bir seviyeye taşırken, kurumsallığın sürekliliğini sağlıyoruz.", link: "/servicesConsulting" },
              { title: "Ürünlerimiz", description: "İşinizi zirveye taşıyacak, güçlü dijital çözümlerimiz.", link: "/products" },
            ].map((service, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-md transition-shadow duration-200">
                <h4 className="text-xl font-semibold text-gray-800 mb-2">{service.title}</h4>
                <p className="text-gray-600 mb-4">{service.description}</p>
                <a href={service.link} className="text-sidoma-blue hover:text-indigo-800 hover:underline transition-colors duration-200">
                  Daha Fazlası →
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesDevelopment;
