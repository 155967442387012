import React from "react";

const CustomerRatio = () => {
  return (
    <div className="flex flex-col items-center justify-center p-8 animate-fadeInUp">
      {/* Başlık ve Alt Başlık */}
      <div className="text-center mb-8">
        <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold text-gray-800">
        İşinize Değer Katan
        </h1>
        <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold text-blue-500">
          Dijital Çözümler
        </h1>
        <h1 className="text-base  md:text-xl font-medium text-gray-500 mt-4">
        Sidoma, işinize değer katacak dijital çözümlerle verimliliğinizi artırır ve süreçlerinizi modernleştirir. 
        <p>Teknolojiyi en iyi şekilde kullanarak iş dünyasında fark yaratmanızı sağlıyoruz.</p> 
        </h1>
      </div>

      {/* Görseller */}
      <div className="flex justify-center filter grayscale hover:grayscale-0 transition duration-300">
        <img
          src="../referencesPeople.png"
          alt="Person"
          className=" object-cover md:h-96"
        />
      </div>

      {/* İstatistik Kutuları */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 w-full max-w-4xl bg-sidoma-blue p-10 rounded-[30px] md:h-60 ">
        {/* 1. Kart */}
        <div className="bg-sidoma-white p-6 rounded-lg  flex justify-center items-center col-span-1 md:col-span-2 transform transition duration-300 hover:scale-105">
          <h3 className="text-6xl font-bold font-clashDisplay">120+<span className="text-lg font-normal font-archivo"> firma</span></h3>
        </div>

        {/* 2. Kart */}
        <div className="bg-white p-6 rounded-lg text-gray-900 flex flex-col justify-center items-center col-span-1 transform transition duration-300 hover:scale-105">
          <h3 className="text-5xl font-extrabold font-clashDisplay">10+</h3>
          <p className="text-sm">Yıllık Deneyim</p>
        </div>

        {/* 3. Kart */}
        <div className="bg-white p-6 rounded-lg text-gray-900 flex flex-col justify-center items-center col-span-1 transform transition duration-300 hover:scale-105">
          <h3 className="text-5xl font-extrabold font-clashDisplay">%90+</h3>
          <p className="text-sm">Müşteri Memnuniyeti</p>
        </div>

      </div>
    </div>
  );
};

export default CustomerRatio;
