import React from "react";
import { Link } from "react-router-dom";

const BlogCard = ({ posts }) => {
    return (
      <>
        {posts.map((post) => (
          <div key={post.id} className="max-w-sm  bg-white border border-gray-200 rounded-lg shadow transform transition duration-300 hover:scale-105">
            <Link to={`/blog/${post.id}`}>
              <img className="rounded-t-lg w-full h-48  object-cover" src={post.image} alt="" />
            </Link>
            <div className="p-5">
              <Link to={`/blog/${post.id}`}>
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                  {post.title}
                </h5>
              </Link>
              <p className="mb-3 font-normal text-gray-700 ">
                {post.description}
              </p>
              <p className="mb-3 font-normal text-gray-400 ">
                {post.date}
              </p>
              <Link
                to={`/blog/${post.id}`}
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-sidoma-white hover:bg-sidoma-white-hover  h-11 rounded-md  bg-sidoma-blue transition duration-200 ease-in-out"
              >
                Read more
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
                
              </Link>
            </div>
          </div>
        ))}
      </>
    );
  };

export default BlogCard;
