import React from "react";

const ErrorPopup = ({ onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-10 rounded-lg shadow-lg text-center">
      <p className="text-red-600 font-bold md:text-xl">Mesaj gönderilemedi, lütfen tekrar deneyin.</p>
      <button
        onClick={onClose}
        className="mt-4 px-6 py-1 bg-red-600 text-white rounded hover:bg-red-700"
      >
        Kapat
      </button>
    </div>
  </div>
);

export default ErrorPopup;